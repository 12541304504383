@charset "UTF-8";
@mixin sort_bg {
    background-repeat: no-repeat;
    background-position: center right;
}
table.dataTable{
    width: 100% !important;
}

table.dataTable thead {
    & .sorting {
        @include sort_bg;
        background-image: url("/img/sort_both.png");
    }
    & .sorting_asc {
        @include sort_bg;
        background-image: url("/img/sort_asc.png");
    }
    & .sorting_desc {
        @include sort_bg;
        background-image: url("/img/sort_desc.png");
    }
    & .sorting_asc_disabled {
        @include sort_bg;
        background-image: url("/img/sort_asc_disabled.png");
    }
    & .sorting_desc_disabled {
        @include sort_bg;
        background-image: url("/img/sort_desc_disabled.png");
    }
}
.dataTables_wrapper {

    padding: 0 5px;

    & .dataTables_filter {
        width: 30rem;
        //@extend .right;
        & i {
            font-size: 2rem;
            float: left;
            margin-right: .5rem;
        }
        & input {
            width: calc(100% - 7rem);
        }
        & .btn-floating {
            margin-right: .5rem;
        }
    }
    & .dataTables_info {
        font-size: .9rem;
        float: left;
    }
    & .dataTables_paginate {
        @extend .right;
        padding-top: 0.25em;
        & a {
            margin: 0 .5rem;
        }
        & .paginate_button {
            @extend .btn;
        }
        .paginate_button:hover {
            @extend .btn:hover;
        }
        & .paginate_button.disabled,
        & .paginate_button.current,
        & .paginate_button.disabled:hover {
            @extend .btn.disabled;
        }
    }
    & .dataTables_processing {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 40px;
        margin-left: -50%;
        margin-top: -25px;
        padding-top: 20px;
        text-align: center;
        font-size: 1.2em;
        background-color: white;
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    }
    &:after {
        visibility: hidden;
        display: block;
        content: "";
        clear: both;
        height: 0;
    }
    @media screen and (max-width: $small-screen) {
        & .dataTables_info,
        & .dataTables_paginate {
            float: none;
            text-align: center;
        }
        & .dataTables_paginate {
            margin-top: 0.5em;
        }
    }
    @media screen and (max-width: $medium-screen) {
        & .dataTables_length,
        & .dataTables_filter {
            float: none;
            text-align: center;
        }
        & .dataTables_filter {
            margin-top: 0.5em;
        }
    }
}