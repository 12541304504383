
.primaria{background-color: $primary-color;}
blockquote{border-left-color: $primary-color;}
#logo-banner{background-image: linear-gradient(to right, #FFFFFF, $primary-color);}

.secundaria,  .btn, .btn-floating, .paginate_button, #next_prev{background-color: $secondary-color; }


.primaria-text{color: $primary-color;}
.secundaria-text,.dropdown-content li>a, .dropdown-content li>span, .chosen-container .chosen-results, .chosen-container-active.chosen-with-drop .chosen-single{color: $secondary-color;}

.tbAlinhamento, .tbAlinhamento td{text-align: center}

a{color: inherit};

a.current{ background-color:  #DFDFDF !important; color: #9F9F9F !important;}

.anexo_tramite{line-height: 2.5rem; height: 2.5rem}
.anexo_tramite i{line-height: 2.5rem}

#listaTramites .arrow_box_left, #listaTramites .arrow_box_right{margin-top: 20px;}
.arrow_box_left {position: relative; background-color: #fafafa; border: 1px solid #ccc;; border-radius: 0 8px 8px 8px;}
.arrow_box_left:after, .arrow_box_left:before {right: 100%;top: 30px;border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;}

.arrow_box_left:before { border-color: rgba(204, 204, 204, 0);  border-right-color: #ccc; border-width: 0 22px 22px 22px; margin-top: -31px;}
.arrow_box_left:after { border-color: rgba(250, 250, 250, 0); border-right-color: #fafafa; border-width: 0 20px 20px 0;  margin-top: -30px;}

.arrow_box_right {position: relative; background-color: #efffeb; border: 1px solid #ccc;; border-radius: 8px 0 8px 8px;}
.arrow_box_right:after, .arrow_box_right:before {left: 100%;top: 30px;border: solid transparent;content: " ";height: 0;width: 0;position: absolute;pointer-events: none;}

.arrow_box_right:before { border-color: rgba(204, 204, 204, 0); border-left-color: #ccc; border-width: 0 22px 22px 22px; margin-top: -31px;}
.arrow_box_right:after { border-color: rgba(250, 250, 250, 0); border-left-color: #efffeb; border-width: 0 20px 20px 20px; margin-top: -30px;}

.blink {  animation:0.7s blinker ease-out infinite;-webkit-animation:0.7s blinker ease-out infinite; -moz-animation:0.7s blinker ease-out infinite;}
@-moz-keyframes blinker { 0% { opacity: 1.0; }50% { opacity: 0.0; } 100% { opacity: 1.0; }}

@-webkit-keyframes blinker { 0% { opacity: 1.0; } 50% { opacity: 0.0; } 100% { opacity: 1.0; }}

@keyframes blinker { 0% { opacity: 1.0; } 50% { opacity: 0.0; }100% { opacity: 1.0; }}

.icone_atalho{display: none; padding: 0 !important; margin-left: -10px !important;}

.baloes_home{margin-top: 0; margin-bottom: 0; padding-top: 0; color: white; border-top: 2px solid white; padding-top: 20px;padding-bottom: 15px;}

.badge_header{ height: 20px; width: 25px; position: absolute; top:4px;  margin-left: 30px; border: 1px solid red; border-radius: 50%; line-height: normal; background-color: red; text-align: center;}
#nav_atendimento .badge_header{margin-top: 20px;margin-left: 0 !important;}
#navbar-superior .badge_header{margin-bottom: 4px;}

.badge_sidenav{ min-width: 1.5rem; border: 1px solid red; padding: 0 4px; border-radius: 50%; background-color: red;}

.btn-alerta{border: 1px solid red; color: black; margin: 0.5rem}

.btn_destaque_msg{border-bottom: 1px solid #ccc;padding: 0px 6px;font-weight: 700;}

.btn-opt-agenda{padding: 0 0.5rem; margin: 0 5px 0 0;}
.btn-opt-agenda i {    margin-right: 7px;}

blockquote{white-space: pre-wrap}
blockquote a, #tinymce a{ text-decoration: underline; color: blue;}
blockquote p{margin: 0}
blockquote.mural-aviso{white-space:normal;}
blockquote.mural-aviso.flow-text{line-height:normal;}

body {display: flex;min-height: 100vh;flex-direction: column; }

.bold{font-weight: 700;}

.borda-1{border: 1px solid #ccc}
.borda-1-rigth{border-right: 1px solid #ccc}

.btns-form .btn, .btns-form .btn-flat { width: 20rem; text-align: center; margin-right: 10px;}

.chosen-container.chosen-container-single {width: 100% !important; margin-bottom: 20px;}

.circle_home{border: 3px solid white; color: white; width: 10rem; height: 10rem; margin: 0 auto; line-height: 7rem; text-align: center}
.circle_home h2{line-height: 6rem}

.circle.grafico{ height: 19rem; width: 19rem;  margin: 0 auto;}

.circle.grafico h2{ line-height: 19rem;}

.circle_etapa{float: left; margin-right: 1.5rem; border: 3px solid white; color: white; width: 5rem; height: 5em; line-height: 4rem; text-align: center}

.collection-item.odd{background-color: #f1f1f1}/*Lista zebrada*/

.collapsible_message  .collapsible-header{padding: 15px; background-color: #f2f2f2; border: 1px solid #ddd}
.collapsible_message  .collapsible-header img{height: 52px; padding-right: 25px;}
.collapsible_message  .collapsible-header h5{margin-top: 0}
.collapsible_message  .collapsible-body blockquote{margin-left: 6%; font-size: 1.3rem; line-height: 1.6rem}
.collapsible_message li{margin-bottom: 10px}

.container-listagem-atendimentos{background-color: transparent !important;}

#container-totais span{display: inline-block; margin: 0 2rem}

.container.boxShadow{background-color: #FFF;}

.container-alerta-agenda{padding: 0 10px; margin-bottom: 10px;}

#content-select-empresa{
  width: 50%;
  position: absolute;
  margin: 0 auto;
  top: 17%;
  left: 0;
  right: 0;
  box-shadow: 0 16px 28px 0 rgba(0,0,0,0.22), 0 25px 55px 0 rgba(0,0,0,0.21);
}
#content-select-empresa img{max-height: 350px}
#content-select-empresa li{
  margin: 1rem 0.5rem;
  border-radius: 5px;
}

.content-responsive-table{overflow-x: auto !important;}
.content-responsive-table td{white-space: nowrap}
.content-btns-options{text-align: right}
.content-btns-options .btn,.content-btns-options .btn-flat {margin-bottom: 1rem;}

.corpo-container{width: 100%; margin: 0 auto 0 auto;}

.dataTables_length label{ float: left;}
.dataTables_length select{display: inline-block; width: auto; height: 1.5rem; min-height: 25px;}
.dataTables_wrapper .dataTables_filter{float: right}
.dataTables_wrapper .dataTables_filter label input{height: 1.5rem; text-indent: 10px}

.datepicker-container-btn-open{height: 36px;  width: 36px; position: absolute; padding: 8px; display: inline-block; margin-left: -36px;}
.datepicker-container-btn-open:hover{cursor: pointer}

form .row{margin-bottom: 0;}

#image-preview {width: 200px;height: 145px;position: relative;overflow: hidden;background-color: #ffffff;color: #ecf0f1; }
#image-preview input { line-height: 200px; font-size: 200px; position: absolute; opacity: 0; z-index: 10; }
#image-preview label {position: absolute; z-index: 5; opacity: 0.8; cursor: pointer; background-color: #bdc3c7;  width: 200px; height: 50px;  font-size: 20px;  line-height: 50px; text-transform: uppercase; top: 0;  left: 0; right: 0; bottom: 0; margin: auto;  text-align: center; }

html{ line-height: 1rem;min-height: 100%;}

html#index{background-image: linear-gradient(to bottom, #2A3F59, #496f9c);}

#home_atendimento{background-color: #3f51b5; margin-bottom: 0;color: white}

.icon-link{font-size: inherit;}

.icon-remove-campo{margin-top: -6%;}

.icon-remove-desdobramento{margin-top: 20%;}

div.invalid{color: red;  margin-top: -15px; font-size: 0.8rem; position: absolute;}
.file-field .invalid { margin-top: -8px; font-size: 0.8rem; text-transform: none;}
.input-field label{ text-overflow: ellipsis;white-space: nowrap;overflow: hidden;max-width: 95%;}


.dropdown-content li>span>label {overflow: visible;}

.spinner {  position: absolute;  top: 46%;  left: 48%;  width: 100px;  text-align: center;}

.spinner > div {  width: 25px;  height: 25px;  background-color: #ffffff;  border-radius: 100%;  display: inline-block;  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;  animation: sk-bouncedelay 1.4s infinite ease-in-out both;}

.spinner .bounce1 {  -webkit-animation-delay: -0.32s;  animation-delay: -0.32s;}

.spinner .bounce2 {  -webkit-animation-delay: -0.16s;  animation-delay: -0.16s;}

@-webkit-keyframes sk-bouncedelay {  0%, 80%, 100% { -webkit-transform: scale(0) }  40% { -webkit-transform: scale(1.0) }}

@keyframes sk-bouncedelay { 0%, 80%, 100% { -webkit-transform: scale(0); transform: scale(0); } 40% { -webkit-transform: scale(1.0); transform: scale(1.0);}}

.jquery-waiting{cursor: wait; position: fixed; left: 0px; top:0px; margin:0px; width: 100%; height: 100%; display:block; z-index: 9999997; background: black; overflow: hidden;}
.jquery-waiting .content{ top: 52%; color: #dadada; position: relative; text-align: center; font-size: 1.7rem}

label .material-icons {font-size: inherit;line-height: inherit; margin: auto;}


li.li-desdobramento:first-child {border-top: 1px solid #dadada;}

li.li-desdobramento { display: flex; border-bottom: 1px solid #dadada; padding: 10px 0;}

.li-campo{background: url("/img/swap_vert.png") no-repeat left center; background-position-x: 3px;}

.list .selected{border: 1px solid #1E90FF !important; font-weight: bold; }

.lista-atendimento ul{background-color: #F1F1F1;}
.lista-atendimento ul li{font-size: 13px;}
.lista-atendimento.tabs{ color: #fff;}
.lista-atendimento table thead tr{font-size: 12px}
.lista-atendimento table tbody tr{font-size: 12px}

.lista-solucoes .collapsible-body{padding-top: 10px; border-bottom: none}

#listaTramites .row{margin-top: 5px; margin-bottom: 0; padding: 15px;}
#listaTramites pre {padding-left: 10px; border-left: 1px solid #ccc;}

#logo-banner{height: 100%; padding-left: 30px; padding-right: 80px; color: #000; font-style: italic; background-image: linear-gradient(to right, #FFFFFF, #3F51B5);}

main {flex: 1 0 auto;}

.material-icons{color: inherit}

.msgNaoLida{background-color: #f2f2f2; font-weight: 700}

#menu-adm a{color: black}

#navbar-superior{font-weight: 500 !important}

.num_tramite{margin-top: 15px;padding-right: 10px;float: left}

.pesquisa-container{height: auto; margin-bottom: 5px; margin-top: 5px;width: 100%;}
.pesquisa-container .btn{margin-top: 16px;}

pre{font-family: inherit; font-size: inherit; white-space: pre-wrap; overflow: hidden; line-height: 1.5rem}

pre p, pre ol, pre a, pre li{line-height: initial; margin: initial;}

.progress{height: 1rem; border-radius: 0.5rem}

.progress-waiting{cursor: wait; position: fixed; left: 0px; top:0px; margin:0px; width: 100%; height: 100%; display:block; z-index: 9999997; background: black; overflow: hidden;}
.progress-waiting .progress{width: 50%; left: 25%; top: 50%; height: 10px; border-radius: 5px; }
.progress-waiting .msg-progress{ color: #fff; top: 55%; left: 0; width: 100%; position: absolute; text-align: center; padding: 0 5% 0 5%;}

.quebra-texto{max-width: 450px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap}


#sidenav-notificacoes{min-width: 350px;}
.side-nav a.icone_atalho{display: none};

strong{font-weight: bold;}

.row.even{background-color: transparent}
.row.odd{background-color: #eee}

table th{text-align: center}
table{padding: 10px;}

.tabs .tab{border-bottom: 1px solid #ccc;}

td.break-text{max-width: 200px; overflow: hidden;text-overflow: ellipsis; white-space: nowrap;}

#titulo_home_atendimento{font-size: 2rem; line-height: 2rem}

.z-tabs{background-color: transparent !important;font-family: inherit !important;}

/* PORTAL DO CLIENTE */

.anexo_tramite_cliente{height: 2.5rem; line-height: 2.5rem}
.anexo_tramite_cliente i{line-height: 2.5rem}

.box-tramite{background-color: #fafafa; border: 1px solid #ccc; border-radius: 8px; margin-bottom: 1rem}
.box-tramite.bg_green{background-color: #efffeb;}
.box-tramite pre{line-height: 1.8rem; min-height: 40px;}

#home_cliente{background-color: #3f51b5; margin-top: -7px; padding-top: 2%; margin-bottom: 0; padding-left: 70px; color: white}
#icon_troca_empresa{margin-right: 15px; border: 1px solid #fff;border-radius: 4px;}

.informativo blockquote{text-align: justify;text-indent: 3rem; line-height: 2rem}

#lista_atendimentos_cliente{box-shadow: none; border: none; margin-top: 0}
#lista_atendimentos_cliente .collapsible{border: none}
#lista_atendimentos_cliente .collapsible-body {padding: 0;}
#lista_atendimentos_cliente .collapsible-header i{font-size: 2rem; float: none; display: inline}
#lista_atendimentos_cliente p{padding: 5px; font-size: 1.25rem; margin:0;}

#nav_portal_cliente, #nav_atendimento{box-shadow: none;}

#menu-mobile .collection{margin: 0;border: none}
#menu-mobile .collection-item{padding: 15px 20px}
#menu-mobile .collection-item a{height: auto; line-height: inherit}
#menu-mobile .collection-item i{height: inherit; line-height: inherit}
#menu-mobile .collection .collection-header{background-color: #3f51b5; color: white; padding: 10px 5px;}

/* FIM DO PORTAL DO CLIENTE */


/* COMERCIAL */

.border-blue{border-color: #3f51b5 !important}
.border-grey{border-color: #ccc !important}
.border-green{border-color: #009688 !important}
.border-red{border-color: #ef5350 !important}

.card-panel.negociacao{border-left: 0.6rem solid; padding: 5px 20px;}

.card-panel.negociacao img{margin-top: 0.5rem; max-width: 2.5rem; height: 2.3rem;}
.negociacao .informacoes .id{display: inline-block;height: 2.5rem;line-height: 2.5rem; border-right: 1px solid #ccc; padding-right: 10px; vertical-align: bottom; font-size: 2rem;}
.negociacao .informacoes .cliente{display: inline-block;margin-left: 0.5rem;}
.negociacao .options i{display: inline-block; margin: 0.7rem 0.5rem 0 0.5rem; font-size: 2rem;}

.container-header-lista-negociacao{border-top: 4px solid #ccc; margin-bottom: 0;}

.content-etapas{ padding: 1rem 0;}

.header-lista-negociacao{border-left: 1px solid #ccc; border-bottom: 1px solid #ccc}


/* FIM DO COMERCIAL */


/* AGENDA */

#aba-agenda .lista-agendamento {padding: 1rem;}
#aba-agenda h4{font-size: 1.5rem}

#agenda tbody tr td{ height: 7rem;  vertical-align: baseline; padding: 0;  width: 14%}

#agenda thead tr th{ text-align: center; font-weight: 700;}

#agenda tbody tr td:hover{ cursor: pointer; background-color: #fafafa;}
#agenda .day{ display: block; text-align: right; margin: 10px 10px 3px 0;}

.evento-dia { border-radius: 2px; margin: 2px; padding: 3px 5px; text-align: left; font-size: 0.8rem;  }

.evento-mais{ display: block; margin: 5px;}

#header_agenda{  text-align: center; font-size: 2rem; line-height: 2.5rem;}

.icon-agenda-ok{line-height: inherit; margin: 0; float: left}
.collapsible-header .icon-agenda-ok{line-height: 2rem;}

#interval_date{ float: right; border-radius: 5px; height: 2.5rem; padding: 0px 6px; margin-right: 20px; font-size: initial;}
#interval_date a, #hoje, #refresh_agenda, #novo{ box-shadow: none; font-size: initial; padding: 0 10px}

#interval_date a { padding: 0 10px 0 10px;}

#interval_date #dia{ margin-left: -4px; border-left: 1px solid #ccc;border-radius: 0 5px 5px 0;}
#interval_date #mes{border-radius: 5px 0 0 5px;}
#interval_date #semana{margin-left: -4px; border-left: 1px solid #ccc; border-radius: 0;}

.lista-agendamento{ box-shadow: none; border: none;}

.lista-agendamento blockquote{white-space: initial; margin-top: -1px; margin-left: 1px; padding: 1rem 1rem 1rem 1.5rem;}
.lista-agendamento blockquote.secundaria{background-color: initial !important;}

.lista-agendamento li{ margin-bottom: 0.5rem}

.lista-agendamento .corpo{ border-radius: 5px; border: 1px solid #dadada; line-height: 2rem; min-height: 2rem;padding: 0 0.5rem 0.5rem 1rem;}
.lista-agendamento .corpo.m6{width: 49%!important;margin-right: 0.5rem; margin-bottom: 0.75rem;}

#next_prev{background-color: #26a69a; float: left; border-radius: 3px; height: 36px; margin: 0 6px 0 6px; font-size: initial;}

#next_prev i{ line-height: 36px; font-size: 2rem;}
#next_prev i:hover, #interval_date a:hover{ cursor: pointer;}

#prev_mont{border-radius: 5px 0 0 5px;}

#next_mont{ border-radius: 0 5px 5px 0; border-left: 1px solid #ccc; margin-left: -5px;}

#refresh_agenda , #novo{margin-left: 6px;}
#refresh_agenda i{ width: 1.5rem}

table#agenda{ table-layout: fixed;}

.tdSelected{ border: 2px solid #ef9a9a !important;}



/* FIM AGENDA*/