@media #{$medium-only}{

    .active.collapsible-body ul li{padding-left: 10px;}

    .corpo-container{width: 100%; margin: 0 auto 0 auto;}

    .content-btns-options{text-align: center}

    .dataTables_wrapper .dataTables_info{margin-left: 15px;}
    .dataTables_wrapper .dataTables_paginate .paginate_button{margin-top: 10px;}
    .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate{float: left}

    .display-erro{border: 1px solid #ef9a9a; border-radius: 3px; line-height: 3rem; font-weight: 700;  margin: 10px 25px 10px 25px; text-align: center;}

    li .collapsible-header{font-weight: 700; font-size: 18px}

    .modal .modal-content{padding: 7px;}

    .modalPequena{width: 65% !important;}
    .modalMedia{width: 75% !important;}
    .modalGrande{ width: 85% !important;};

    .side-nav{color: #000;}
    .side-nav li{padding: 0}

    table caption{display:block;}

    table.responsive-table{float: left}

    .tdStatus{min-height: 3px !important; height: 3px !important; padding: 0 !important}

    .tbAtendimentos{white-space: nowrap;}


    /* PORTAL DO CLIENTE */
    #content-select-empresa{width: 75%}
    #content-select-empresa img{max-height: 250px}

    #lista_atendimentos_cliente .prefix{line-height: 3rem;position: relative; top: 13px;}

    #listaTramitesCliente{padding: 0 15px 0 15px}

    #nav_portal_cliente{box-shadow: none}
    #nav_portal_cliente i{float: left !important; margin-right: 15px;}
    #nav_portal_cliente li a{padding: 0 7px}

    #modal_selecionarEmpresa .collection-item{padding-left: 10px; padding-right: 10px}
    #modal_selecionarEmpresa .collection-item button{padding-left: 20px; padding-right: 20px; height: auto}
    #modal_trocarSenhaResponsavel{width: 600px}
    /*FIM PORTAL DO CLIENTE*/
}