@media #{$large-and-up}{

    .container_cliente{width: 70%; margin: 0 auto 0 auto;}
    .container_cadastro_cliente{ margin: 0 auto 0 auto; max-width: 1345px;}

    .container-header-lista-negociacao{display: flex;  flex-direction: row;}

    .cursor-hand:hover{cursor: pointer;}
    .cursor-move:hover{cursor: move}

    .display-erro{border: 1px solid #ef9a9a; border-radius: 3px; line-height: 3rem; margin: 10px 25px 10px 25px; text-align: center;}

    #navbar-superior .dropdown-content{min-width: 280px; top: 64px !important;}

    #image-preview {width: 200px; height: 145px;}

    .icon-link:hover{cursor: pointer}

    header i{font-size: 2rem !important;}

    li ul.collapsible{margin: 0}

    .modalPequena{width: 60% !important;}
    .modalMedia{width: 70% !important;}
    .modalGrande{ width: 90% !important;}

    #modal_alerta{width: 50%}

    table>tbody>tr.tr_red:hover{background-color: #ffd8d8 !important}
    table>tbody>tr.tr_amber:hover{background-color: #fdffcd !important}
    table>tbody>tr.tr_blue:hover{background-color: #cecefe !important}
    table>tbody>tr.tr_green:hover{background-color: #cefed4 !important}

    .tbAtendimentos tbody{font-size: 0.8rem;}
    .tdAtendimento small .chip{height: 24px; line-height: 24px; margin-left: 5px;}
    .tdAtendimento:hover{cursor: pointer}
    .tdAtendimento small, .iniciarNegociacao small{ color: #01579b;  font-size: 100%; font-family: calibri;  font-weight: normal;}
    .tdStatus{width: 4px !important; padding: 0;}

    /* PORTAL DO CLIENTE */

    #lista_atendimentos_cliente .collapsible-header{text-align: center; font-size: 2rem;  padding: 0 15% 0 15%}

    #lista_atendimentos_cliente .prefix{line-height: 4rem;position: relative; top: 5px;}

    #listaTramitesCliente{padding: 0 20px 0 20px}

    #modal_trocarSenhaResponsavel{width: 600px}

    #nav_portal_cliente, #nav_atendimento{height: 86px;}
    #nav_portal_cliente .brand-logo, #nav_atendimento .brand-logo{line-height: 80px;}
    #nav_portal_cliente a, #nav_atendimento a{text-align: center}
    #nav_portal_cliente i{height: 2rem;}

    #nav_atendimento i{height: 86px; line-height: 86px; font-size: 2rem;}

    /*FIM PORTAL DO CLIENTE*/
}

@media only screen and (min-width: 993px) and (max-width: 1500px){
    #nav_portal_cliente{ padding: 0}
}

@media only screen and (min-width: 1601px){
    #nav_portal_cliente, #nav_atendimento{padding: 0 15% 0 15%}

}