
// Icons
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

// Variables
@import "variables";

// Materialize
@import "vendor/bower_components/materialize/sass/materialize.scss";

//Datatables.net
@import "datatables";

//Global
@import "general";

//Desktop
@import "desktop";

//Mobile
@import "mobile";

//Tablet
@import "tablet";