@media #{$small-and-down}{

    .active.collapsible-body ul li{padding-left: 10px;}

    .corpo-container{width: 100%; margin: 0 auto 0 auto;}

    .container-listagem-atendimentos{background-color: transparent !important;}

    .content-btns-options{text-align: center}

    .content-etapas{padding: 0}
    .content-etapas>div{ border-bottom: 1px solid white;padding: 10px 0.75rem !important}

    .dataTables_wrapper .dataTables_filter label input{height: 1.5rem; text-indent: 10px; max-width: 150px !important;}
    .dataTables_wrapper .dataTables_info{margin-left: 15px;}
    .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_paginate{float: left}
    .dataTables_wrapper .dataTables_paginate .paginate_button{margin-top: 10px;}

    .display-erro{border: 1px solid #ef9a9a; border-radius: 3px; font-weight: 700; padding: 5px;  margin: 10px 25px 10px 25px; text-align: center;}

    .side-nav .collapsible-header i{height: inherit}

    li .collapsible-header{font-weight: 700; font-size: 18px}

    .lista-solucoes h5{ display: inline-block}

    #listaTramites pre { padding-left: 0; border-left: none;  }

    .modal .modal-content{padding: 7px;}

    .modalPequena, .modalMedia, .modalGrande{width: 95% !important;}

    .progress-waiting .progress{width: 80%; left: 10%}

    .side-nav{color: #000;}
    .side-nav li{padding: 0}

    .pesquisa-container{height: auto; margin-bottom: 0; margin-top: 5px;width: 100%;}
    .pesquisa-container .btn{margin-top: 16px;}

    table caption{display:block;}

    table.responsive-table{float: left}

    .tdStatus{min-height: 3px !important; height: 3px !important; padding: 0 !important}

    .tbAtendimentos{white-space: nowrap;}

    thead{font-size: 11px}


    /* PORTAL DO CLIENTE */
    #content-select-empresa{width: 95%; top:10%;}
    #content-select-empresa img{max-height: 150px}

    #home_cliente{background-color: #3f51b5; margin-top: -7px; padding-top: 10%; margin-bottom: 0; padding-left: 15px; color: white}
    #icon_troca_empresa{padding: 5px;}
    #icon_troca_empresa i{margin-right: 0px; line-height: 2rem;    height: 2rem;    font-size: 2rem;}

    #lista_atendimentos_cliente p{padding: 0; font-size: 1rem}
    #lista_atendimentos_cliente .collapsible-header{line-height: 3rem}
    #lista_atendimentos_cliente .collapsible-header i{font-size: 1.5rem}

    #listaTramitesCliente{padding: 0 10px 0 10px}

    #nav_portal_cliente{box-shadow: none}
    #nav_portal_cliente i{float: left !important; margin-right: 15px;}

    #menu-mobile .collection-item{padding: 15px 20px}
    #menu-mobile .collection-item a{height: auto; line-height: inherit;padding: 0 8px;}
    #menu-mobile .collection-item i{height: inherit; line-height: inherit}
    #menu-mobile .collection{margin: 0}
    #menu-mobile .collection.with-header .collection-item{padding-left: 0}
    #menu-mobile .collection .collection-header{background-color: #3f51b5; color: white; padding: 10px 5px;}

    #modal_selecionarEmpresa .collection-item{padding-left: 0; padding-right: 0}
    #modal_selecionarEmpresa .collection-item button{padding-left: 5px; padding-right: 5px; height: auto}

    /*FIM PORTAL DO CLIENTE*/


}